export const useDevStore = defineStore('dev', () => {
  const i18n = useI18n();

  const dev = reactive({
    locale: i18n.locale,
    spa: false,
    stitchingF: true,
    stitchingB: false,
    // Visits
    realogramLine: undefined,
    realogramFaceId: false,
    // Mobile
    mobileSchema: false,
    // Pages
    massRecount: false,
    // Sfa
    gpsCheck: false,
    scores: false,
  });

  /*************************************************
   * Locale
   *************************************************/
  watch(() => dev.locale, () => {
    i18n.setLocale(dev.locale);
  });

  watch(() => i18n.locale, () => {
    dev.locale = i18n.locale;
  });

  return {
    dev,
  };
}, {
  persist: {
    storage: localStorage,
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDevStore, import.meta.hot))
}
